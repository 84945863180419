import httpUtil from "@/utils/httpUtil";

/**获取订单列表 */
export const selectOrdersInfoPc = async params => httpUtil.post("/api/crmPc/orders/selectOrdersInfoPc", params)

/**修改订单状态 */
export const updateOrderState = async params => httpUtil.post("/api/crmPc/orders/updateOrderState", params)

/**查看物流 */
export const getLogisticInfo = async params => httpUtil.post("/api/crmPc/orders/getLogisticInfo", params)

/**修改订单状态 */
export const updateRefundState = async params => httpUtil.post("/api/crmPc/orders/updateRefundState", params)

/** 删除订单 */
export const delOrders = async params => httpUtil.post("/api/crmPc/orders/delOrders", params)

/** 导出订单 */
export const exportOrdersInfoPc = async params => httpUtil.post("/api/crmPc/orders/exportOrdersInfoPc", params)