<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          订单编号：
          <el-input v-model="ordersNo" placeholder="请输入订单编号"></el-input>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          下单时间：
          <el-date-picker style="width: 100%" v-model="pickerValue" align="right" type="date" placeholder="选择日期"
            :picker-options="pickerOptions" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          用户姓名：
          <el-input v-model="nickeName" placeholder="请输入用户姓名"></el-input>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          联系方式：
          <el-input v-model="phone" placeholder="请输入用户手机号"></el-input>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          商品名称：
          <el-input v-model="goodsName" placeholder="请输入商品名称"></el-input>
        </div>
      </el-col>
      <el-col :xl="24" :lg="24">
        <div class="flex-a-b-c pt10">
          <div>
            订单状态：
            <el-radio style="margin-right: 0;" v-model="orderStatusValue" :label="item.label"
              v-for="(item, index) in orderStatus" :key="'a' + index" border>
              {{ item.text }}
            </el-radio>
            订单类型：
            <el-radio style="margin-right: 0; margin-top: 10px" v-model="orderTypeValue" :label="item.label"
              v-for="(item, index) in orderType" :key="'b' + index" border>
              {{ item.text }}
            </el-radio>
            <el-button type="primary" class="ml10" @click="searchFun">搜索</el-button>
            <div>


            </div>

          </div>

          <div>
            <!-- <el-button style="padding-top: 0; padding-bottom: 0" class="export" @click="exportOrder">
              <div
                style="height: 32px"
                class="d-flex align-items-center justify-content-center"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-save"></use>
                </svg>
                <span>导出</span>
              </div>
            </el-button> -->
          </div>

        </div>

      </el-col>

      <!-- <el-col :xl="6" :lg="6">
        <div class="d-flex align-items-center mt10 nowrap">
          <span>订单编号：</span>
          <el-input placeholder="请输入订单编号" />
        </div>
      </el-col> -->


    </el-row>
    <el-row :gutter="24">
      <!-- <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          用户姓名：
          <el-input placeholder="请输入用户姓名" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          联系方式：
          <el-input placeholder="请输入用户手机号" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          商品名称：
          <el-input placeholder="请输入商品名称" />
        </div>
      </el-col> -->
      <!-- <el-col :xl="12" :lg="12">
        <div class="d-flex align-items-center mt10 nowrap">
          <el-button type="primary" @click="selectOrdersInfoPcAsync">搜索</el-button>
        </div>
      </el-col> -->
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum" :loading="loading" :total="total">
      <template v-slot:table>
        <el-table-column type="index" width="80" align="center" label="序号" />
        <el-table-column prop="ordersNo" align="center" width="160px" label="订单编号" />
        <!-- <el-table-column
          prop="productPicture"
          align="center"
          label="商品图片"
          show-overflow-tooltip
        /> -->
        <el-table-column prop="receiveName" align="center" label="用户姓名" />
        <el-table-column prop="receivePhone" align="center" width="120" label="收货人电话" />
        <el-table-column prop="locationAddress" align="center" width="200px" show-overflow-tooltip label="收货地址">
          <template slot-scope="scope"><span>{{
            scope.row.locationAddress + scope.row.detailedAddress
          }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" align="center" show-overflow-tooltip label="商品名称" />
        <el-table-column prop="orderPrice" align="center" label="订单金额" />
        <el-table-column prop="specAttribute" align="center" label="产品规格" show-overflow-tooltip />
        <el-table-column prop="num" align="center" label="订单数量" />
        <!-- <el-table-column prop="shipping" align="center" label="运费" /> -->
        <!-- <el-table-column prop="distribution" align="center" label="分销佣金" />
        <el-table-column prop="nickname" align="center" label="分销人昵称" /> -->
        <el-table-column prop="createdTime" align="center" show-overflow-tooltip label="下单时间">
          <template slot-scope="scope"><span>{{
            scope.row.createdTime
          }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assembleId" align="center" label="订单状态">
          <template slot-scope="scope"><span>{{
            scope.row | setState()
          }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assembleId" align="center" label="订单来源">
          <template slot-scope="scope"><span>{{
            scope.row.assembleId ? '拼团订单' : '个人订单'
          }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assembleId" align="center" label="商品类型">
          <template slot-scope="scope"><span>{{
            scope.row.isReal ? '实物' : '虚拟'
          }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="specAttribute" align="center" label="备注" show-overflow-tooltip/> -->
        <el-table-column width="150px" align="center" label="操作">
          <template slot-scope="scope">
            <span class="cblue hover_pointer" v-if="scope.row.orderState == 0"
              @click="delExpireOrder(scope.row.ordersId)">
              删除
            </span>
            <span class="cblue hover_pointer" v-else-if="scope.row.orderState == 1"
              @click="onConfirmMsg(scope.row.ordersId, 5, 1)">
              撤销订单
            </span>
            <span class="cblue hover_pointer" v-else-if="scope.row.orderState == 2 && scope.row.refundState == 1"
              @click="showSendGoodsFun(scope.row.ordersId,scope.row.isReal)">
              确认发货
            </span>
            <template v-else-if="scope.row.refundState == 2">
              <span class="cblue hover_pointer" @click="onConfirmMsg(scope.row.ordersId, 1, 2)">
                拒绝退款
              </span>
              <span class="cblue hover_pointer" @click="onConfirmMsg(scope.row.ordersId, 3, 2)">
                确定退款
              </span>
            </template>
            <template v-else-if="scope.row.orderState == 3 && scope.row.logisticNum > 0 && scope.row.isReal">
              <span class="cblue hover_pointer" @click="showlogisticsFun(scope.row.ordersId)">
                查看物流
              </span>
            </template>
            <template v-else>-</template>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="确认发货" :visible.sync="showSendGoods"
      :destroy-on-close="true" width="450px" center>
      <el-form :model="sendGoodsData" ref="sendGoodsData" label-width="78px">
        <el-form-item label="快递公司" prop="type">
          <el-select v-model="sendGoodsData.type" style="width:300px;" placeholder="请选择快递公司">
            <el-option v-for="(name, id) in expressCompany" :key="id" :label="name" :value="id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号" prop="no">
          <el-input v-model="sendGoodsData.no" style="width:300px;" placeholder="请输入快递单号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSendGoods = false">取 消</el-button>
        <el-button type="primary" @click="sendGoods" :loading="loadingBtn">确认发货</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="查看快递物流" :visible.sync="showlogistics"
      :destroy-on-close="true" width="650px" center>
      <div v-loading="logisticsLoading">
        <div class="d-flex mb10">
          <div style="width:80px;">联系人</div>
          <div>{{ logisticsData.courier }}</div>
        </div>
        <div class="d-flex mb10">
          <div style="width:80px;">联系方式</div>
          <div>{{ logisticsData.courierPhone }}</div>
        </div>
        <div class="d-flex mb10">
          <div style="width:80px;">快递公司</div>
          <div>{{ logisticsData.expName }}</div>
        </div>
        <div class="d-flex mb20">
          <div style="width:80px;">快递单号</div>
          <div>{{ logisticsData.number }}</div>
          <div @click="copyNo(logisticsData.number)" class="el-icon-document-copy copyNo"></div>
        </div>
        <div :style="{ height: (100 * logisticsList.length) + 'px' }">
          <el-steps direction="vertical" :active="logisticsList.length">
            <el-step :title="row.time" icon="el-icon-success" status="process" :description="row.status"
              v-for="(row, index) in logisticsList" :key="index"></el-step>
          </el-steps>
        </div>
        <br>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectOrdersInfoPc,
  updateOrderState,
  delOrders,
  updateRefundState,
  exportOrdersInfoPc,
  getLogisticInfo
} from "@/api/order";
import { getDataTimeSec, copyText } from "@/utils";
export default {
  name: "orderList",
  components: {
    commonTable,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      orderStatusValue: '', //订单状态
      orderTypeValue: "", //订单类型
      pickerValue: "", //下单时间
      ordersNo: '',//订单编号
      goodsName: '',//商品名称
      phone: '',
      nickeName: '',
      searchCondition: '', //搜索时间
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index
      loading: '',
      total: 0, //总条数
      start: '',
      end: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      orderStatus: [
        //订单状态
        { text: "全部订单", label: "" },
        { text: "待付款", label: 1 },
        { text: "待发货", label: 2 },
        { text: "待收货", label: 3 },
        { text: "已完成", label: 4 },
      ],
      orderType: [
        //订单类型
        { text: "全部订单", label: "1" },
        { text: "分销订单", label: "2" },
        { text: "普通订单", label: "3" },
        { text: "拼团订单", label: "4" },
      ],
      tableData: [],
      //发货配置
      showSendGoods: false,
      sendGoodsData: {
        no: "",
        type: "",
        orderState: 3,
        ordersId: "",
      },
      loadingBtn: false,
      //快递公司
      expressCompany: {
        STO: "申通快递",
        SFEXPRESS: "顺丰快递",
        ZTO: "中通快递",
        YUNDA: "韵达快递",
        YTO: "圆通快递",

        all: "其他"
      },
      //物流配置
      showlogistics: false,
      logisticsLoading: false,
      logisticsData: {},
      logisticsList: [],
    };
  },
  created() {
    this.selectOrdersInfoPcAsync();
    this.rowspan(0, "orderNumber");
    this.rowspan(8, "nameOrtelephone");
    this.rowspan(9, "orderType");
  },
  watch: {
    pickerValue(val, old) {
      //-console.log(val)
      if (val == null) {
        return this.searchCondition = ''
      }
      this.searchCondition = getDataTimeSec(val, 'yy-mm-dd') + " 00:00:00"
      // this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      // this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59"
      // //-console.log(this.start)
      // //-console.log(this.end)
    }
  },
  filters: {
    setState(item) {
      let obj = {
        1: {
          0: '已失效',
          1: '待付款',
          2: '待发货',
          3: '待收货',
          4: '已完成',
          5: '订单取消',
          6: '退款成功',
        },
        2: '退款中',
        3: '退款成功',
      };
      return item.refundState == 1 ? obj[item.refundState][item.orderState] : obj[item.refundState];
    },
    setDate(date) {
      return getDataTimeSec(date)
    }
  },
  methods: {
    copyNo(no) {
      copyText(no)
      this.$message.info("复制成功")
    },
    /**@method 显示物流 */
    showlogisticsFun(orderId) {
      this.showlogistics = true;
      this.logisticsLoading = true;
      getLogisticInfo({ orderId }).then(res => {
        this.logisticsLoading = false;
        if (res.code === 200) {
          this.logisticsData = res.data.logisticJson;
          this.logisticsList = res.data.logisticJson.list ? res.data.logisticJson.list : [];
        }
      })
    },
    /**@method 显示发货 */
    showSendGoodsFun(ordersId, isReal) {
      if (isReal) {
        this.sendGoodsData.ordersId = ordersId;
        this.showSendGoods = true;
      } else {
        let params = {
          orderState: 3,
          ordersId: ordersId,
        }
        updateOrderState(params).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "发货成功",
            });
            this.selectOrdersInfoPcAsync()
          } else {
            if (err.code === 201) {
              this.$message.error(err.message)
            }
          }
        }).catch((err) => {
          if (err.code === 201) {
            this.$message.error(err.message)
          }
        });
      }


    },
    /**@method 提交发货 */
    sendGoods() {
      let params = {
        ...this.sendGoodsData
      }
      if (params.type == 'all') {
        delete params.type;
      }
      this.loadingBtn = true;
      updateOrderState(params).then(res => {
        this.loadingBtn = false;
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "发货成功",
          });
          this.showSendGoods = false;
          this.selectOrdersInfoPcAsync()
        } else {
          if (err.code === 201) {
            this.$message.error(err.message)
          }
        }
      }).catch((err) => {
        this.loadingBtn = false;
        if (err.code === 201) {
          this.$message.error(err.message)
        }
      });
    },
    //导出
    exportOrder() {
      let data = {
        orderState: ''
      }
      exportOrdersInfoPc(data).then(res => {
        let blob = new Blob(["\ufeff", res], { type: 'text/csv,charset=UTF-8' });

        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          window.navigator.msSaveOrOpenBlob(blob, 'fileName.csv');
        } else {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'fileName.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        // const fileName = '订单列表.xls'
        // if ('download' in document.createElement('a')) { // 非IE下载
        //   const elink = document.createElement('a')
        //   elink.download = fileName
        //   elink.style.display = 'none'
        //   elink.href = URL.createObjectURL(blob)
        //   document.body.appendChild(elink)
        //   elink.click()
        //   URL.revokeObjectURL(elink.href) // 释放URL 对象
        //   document.body.removeChild(elink)
        // } else { // IE10+下载
        //   navigator.msSaveBlob(blob, fileName)
        // }

        let link = document.createElement('a');
        let url = window.URL.createObjectURL(res);
        link.href = url;
        link.download = '订单列表.xls';

        link.click();
        window.URL.revokeObjectURL(url);
      })
        .catch(err => {
          //-console.log(err)
        })
    },
    rowspan(idx, prop) {
      this.spanArr[idx] = [];
      this.position = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr[idx].push(1);
          this.position = 0;
        } else {
          if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
            this.spanArr[idx][this.position] += 1; //有相同项
            this.spanArr[idx].push(0); // 名称相同后往数组里面加一项0
          } else {
            this.spanArr[idx].push(1); //同列的前后两行单元格不相同
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 8, 9].includes(columnIndex)) {
        const _row = this.spanArr[columnIndex][rowIndex];
        const _col = _row > 0 ? 1 : 0;
        // //-console.log('第'+rowIndex+'行','第'+i+'列','rowspan:'+_row,'colspan:'+_col)
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    searchFun() {
      this.pageNum = 1
      this.selectOrdersInfoPcAsync();
    },
    /**@method 获取订单列表 */
    async selectOrdersInfoPcAsync() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        createTimes: this.searchCondition,
        orderState: this.orderStatusValue,
        orderType: this.orderTypeValue,
        ordersNo: this.ordersNo,
        goodsName: this.goodsName,//商品名称
        phone: this.phone,
        nickeName: this.nickeName,
      };
      const result = await selectOrdersInfoPc(data);
      this.loading = false
      if (result.data) {

        this.tableData = result.data.pageInfo.list;
        this.total = result.data.pageInfo.total
        this.tableData.forEach(v => {
          v.orderPrice = (v.orderPrice / 100).toFixed(2)
        })
      } else {
        this.tableData = []
      }
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.selectOrdersInfoPcAsync();
    },
    //删除订单
    delExpireOrder(id) {
      this.$confirm('确定要删除这个订单吗？', "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          delOrders({ ordersId: id })
            .then(res => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.selectOrdersInfoPcAsync()
            })
        })
        .catch((err) => {
          //-console.log(err);
        });

    },
    //修改订单状态
    onConfirmMsg(ordersId, state, type) {
      let obj = {
        1: {
          5: '确定要撤销该订单吗？',
          3: '是否确定发货？',
        },
        2: {
          1: '是否拒绝退款？',
          3: '是否确定退款',
        }
      };
      let text = obj[type][state];

      this.$confirm(text, "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          if (type == 1) {
            this.changeOrderState(ordersId, state);
          } else {
            this.changeRefund(ordersId, state);
          }
        })
        .catch((err) => {
          //-console.log(err);
        });
    },
    changeOrderState(id, state) {
      updateOrderState({
        orderState: state,
        ordersId: id,
      })
        .then(res => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.selectOrdersInfoPcAsync()
        })
    },
    changeRefund(id, state) {
      updateRefundState({
        refundState: state,
        ordersId: id,
      })
        .then(res => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.selectOrdersInfoPcAsync()
        })
    }
  },
};
</script>

<style scoped lang='scss'>
.copyNo {
  margin-top: 3px;
  margin-left: 10px;
  cursor: pointer;
}

.orderList-view {
  padding-top: 14px;
}
</style>
